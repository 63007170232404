
import { defineComponent } from 'vue'
import TmButton from '@/components/shared/TmButton.vue'
import type { CampaignsDetailsRateStatsType } from '@/definitions/campaigns/types'
import type { PropType } from 'vue'
import TmProgress from '@/components/shared/TmProgress.vue'
import ReportGraph from '@/components/shared/templates/reports/ReportGraph.vue'
import type { RouteLocationRaw } from 'vue-router'

export default defineComponent({
  components: {
    TmProgress,
    TmButton,
    ReportGraph,
  },
  props: {
    title: {
      type: String,
    },
    btnTitle: {
      type: String,
    },
    percent: {
      type: Number,
    },
    percentColor: {
      type: String,
    },
    stats: {
      type: Array as PropType<CampaignsDetailsRateStatsType[]>,
    },
    link: {
      type: Object as PropType<RouteLocationRaw>,
    },
  },
})
