import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6a948a71"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "campaigns-details-rate-block__content" }
const _hoisted_2 = { class: "campaigns-details-rate-block__percent" }
const _hoisted_3 = { class: "d-flex flex-column gap-10px" }
const _hoisted_4 = { class: "truncate mr-2" }
const _hoisted_5 = { class: "font-weight-medium flex-shrink-0" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tm_button = _resolveComponent("tm-button")!
  const _component_tm_progress = _resolveComponent("tm-progress")!
  const _component_report_graph = _resolveComponent("report-graph")!

  return (_openBlock(), _createBlock(_component_report_graph, {
    icon: "tmi-chart-outline",
    title: "Response rate",
    "empty-text": "Response rate will be shown here once data is available.",
    size: "large",
    class: "campaigns-details-rate-block",
    "no-gap": ""
  }, {
    "report-graph-top-right": _withCtx(() => [
      _createVNode(_component_tm_button, {
        class: "gt-xs",
        to: _ctx.link
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.btnTitle), 1)
        ]),
        _: 1
      }, 8, ["to"])
    ]),
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.percent) + "% ", 1),
        _createVNode(_component_tm_progress, {
          value: _ctx.percent / 100,
          color: _ctx.percentColor,
          size: "16px",
          class: "mb-6"
        }, null, 8, ["value", "color"]),
        _createElementVNode("div", _hoisted_3, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.stats, (item, i) => {
            return (_openBlock(), _createElementBlock("div", {
              key: i,
              class: "d-flex justify-between lh-20"
            }, [
              _createElementVNode("div", _hoisted_4, _toDisplayString(item.name), 1),
              _createElementVNode("div", _hoisted_5, _toDisplayString(item.value), 1)
            ]))
          }), 128))
        ])
      ])
    ]),
    _: 1
  }))
}