
import { defineComponent, ref } from 'vue'
import TmButtonMore from '@/components/shared/TmButtonMore.vue'
import TmDropdown from '@/components/shared/TmDropdown.vue'
import TmTooltip from '@/components/shared/TmTooltip.vue'
import type { ReportCardPercentType } from '@/definitions/shared/templates/types'
import type { PropType } from 'vue'
import TmChip from '@/components/shared/TmChip.vue'
import { useModes } from '@/compositions/modes'

export default defineComponent({
  components: {
    TmChip,
    TmButtonMore,
    TmDropdown,
    TmTooltip,
  },
  props: {
    title: {
      type: String,
    },
    caption: {
      type: String,
    },
    counter: {
      type: String,
    },
    counterAfter: {
      type: String,
    },
    previously: {
      type: String,
    },
    percent: {
      type: Object as PropType<ReportCardPercentType>,
    },
    showActions: {
      type: Boolean,
    },
    tooltip: {
      type: String,
    },
    noHover: {
      type: Boolean,
    },
  },
  setup() {
    const dropdownActive = ref()
    const { isEmptyMode } = useModes()

    return {
      isEmptyMode,
      dropdownActive,
    }
  },
})
