
import { defineComponent } from 'vue'
import ReportEmpty from '@/components/shared/templates/reports/ReportEmpty.vue'
import CampaignsDetailsBlock from '@/components/pages/campaigns/details/CampaignsDetailsBlock.vue'
import type { ReportCardType } from '@/definitions/shared/templates/types'
import type { PropType } from 'vue'
import { useModes } from '@/compositions/modes'
import ReportCards from '@/components/shared/templates/reports/ReportCards.vue'

export default defineComponent({
  components: {
    ReportCards,
    ReportEmpty,
    CampaignsDetailsBlock,
  },
  props: {
    stats: {
      type: Array as PropType<ReportCardType[]>,
      required: true,
    },
  },
  setup() {
    const { isEmptyMode } = useModes()

    return {
      isEmptyMode,
    }
  },
})
