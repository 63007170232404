
import { computed, defineComponent } from 'vue'
import kebabCase from 'lodash/kebabCase'
import type { SizeProp } from '@/definitions/shared/types'
import type { ReportEmptySize } from '@/definitions/shared/templates/types'

export default defineComponent({
  props: {
    title: {
      type: String,
    },
    text: {
      type: String,
    },
    icon: {
      type: String,
    },
    row: {
      type: Boolean,
    },
    size: {
      type: String as SizeProp<ReportEmptySize>,
      default: 'medium',
    },
  },
  setup(props) {
    const kebabSize = computed(() => kebabCase(props.size))

    return {
      kebabSize,
    }
  },
})
