
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'WhiteBlock',
  props: {
    noBorders: {
      type: Boolean,
    },
    shadow: {
      type: Boolean,
    },
  },
})
