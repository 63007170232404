import { toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, renderSlot as _renderSlot, normalizeClass as _normalizeClass, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6fa57e0a"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "report-graph-block__top"
}
const _hoisted_2 = { class: "d-flex align-center font-weight-semi-bold lh-20" }
const _hoisted_3 = {
  key: 0,
  class: "tooltip-wrap"
}
const _hoisted_4 = { class: "text-center lh-20" }
const _hoisted_5 = { class: "d-flex align-center" }
const _hoisted_6 = { class: "report-graph-block__content" }
const _hoisted_7 = { class: "report-graph-block__content-in" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tm_icon = _resolveComponent("tm-icon")!
  const _component_tm_tooltip = _resolveComponent("tm-tooltip")!
  const _component_white_block = _resolveComponent("white-block")!

  return (_openBlock(), _createBlock(_component_white_block, {
    shadow: "",
    class: _normalizeClass(["report-graph-block", {
      'report-graph-block--no-gap': _ctx.noGap
    }])
  }, {
    default: _withCtx(() => [
      (!_ctx.hideTitle)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              _createTextVNode(_toDisplayString(_ctx.title) + " ", 1),
              (_ctx.tooltip)
                ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                    _createVNode(_component_tm_icon, {
                      name: "tmi-help-outline",
                      class: "light--text pointer"
                    }),
                    _createVNode(_component_tm_tooltip, null, {
                      default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.tooltip), 1)
                      ]),
                      _: 1
                    })
                  ]))
                : _createCommentVNode("", true)
            ]),
            _createElementVNode("div", _hoisted_5, [
              _renderSlot(_ctx.$slots, "report-graph-top-right", {}, undefined, true)
            ])
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("div", _hoisted_7, [
          _renderSlot(_ctx.$slots, "default", {}, undefined, true)
        ])
      ])
    ]),
    _: 3
  }, 8, ["class"]))
}