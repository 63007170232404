import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderSlot as _renderSlot, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-d23820ee"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "campaigns-details-block" }
const _hoisted_2 = { class: "headline-18 mb-2 lh-24" }
const _hoisted_3 = { class: "lh-20 neutral--text mb-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.title), 1),
    _createElementVNode("div", _hoisted_3, [
      _renderSlot(_ctx.$slots, "text", {}, () => [
        _createTextVNode(_toDisplayString(_ctx.text), 1)
      ], true)
    ]),
    _renderSlot(_ctx.$slots, "default", {}, undefined, true)
  ]))
}