
import { computed, defineComponent } from 'vue'
import ReportCard from '@/components/shared/templates/reports/ReportCard.vue'
import type { ReportCardType } from '@/definitions/shared/templates/types'
import type { PropType } from 'vue'

export default defineComponent({
  components: {
    ReportCard,
  },
  props: {
    cards: {
      type: Array as PropType<ReportCardType[]>,
    },
    cardsInRow: {
      type: [String, Number],
      default: 'auto',
    },
    showActions: {
      type: Boolean,
    },
    noHover: {
      type: Boolean,
    },
  },
  setup(props) {
    const computedColumnsStyle = computed(() => {
      if (props.cardsInRow === 'auto') { return 'repeat(auto-fit, minmax(0, 1fr))' }

      return `repeat(${props.cardsInRow}, 1fr)`
    })

    return {
      computedColumnsStyle,
    }
  },
})
