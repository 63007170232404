
import { defineComponent } from 'vue'
import TmButton from '@/components/shared/TmButton.vue'
import ReportGraphBlock from '@/components/shared/templates/reports/ReportGraphBlock.vue'
import ReportEmpty from '@/components/shared/templates/reports/ReportEmpty.vue'
import { useModes } from '@/compositions/modes'
import type { SizeProp } from '@/definitions/shared/types'
import type { ReportEmptySize } from '@/definitions/shared/templates/types'

export default defineComponent({
  components: {
    TmButton,
    ReportGraphBlock,
    ReportEmpty,
  },
  props: {
    title: {
      type: String,
    },
    tooltip: {
      type: String,
    },
    icon: {
      type: String,
      default: 'donut_large',
    },
    size: {
      type: String as SizeProp<ReportEmptySize>,
      default: 'medium',
    },
    graphClass: {
      type: String,
    },
    emptyText: {
      type: String,
    },
    noGap: {
      type: Boolean,
    },
    hideTitle: {
      type: Boolean,
    },
  },
  setup() {
    const { isEmptyMode, isEmptyHalfMode } = useModes()
    return {
      isEmptyMode,
      isEmptyHalfMode,
    }
  },
})
