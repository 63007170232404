
import { defineComponent, ref } from 'vue'
import type { PropType } from 'vue'
import ReportGraph from '@/components/shared/templates/reports/ReportGraph.vue'
import DoughnutChart from '@/components/shared/charts/DoughnutChart.vue'
import TmToggleButtons from '@/components/shared/TmToggleButtons.vue'
import TmButton from '@/components/shared/TmButton.vue'

export default defineComponent({
  components: {
    TmToggleButtons,
    TmButton,
    DoughnutChart,
    ReportGraph,
  },
  props: {
    getData: {
      type: Function as PropType<(opens: boolean) => Record<string, any>>,
    },
    showBtns: {
      type: Boolean,
    },
  },
  setup() {
    const currentGraph = ref('opens')

    return {
      currentGraph,
    }
  },
})
