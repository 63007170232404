import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderSlot as _renderSlot, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1b95ac8c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "subhead-semi-bold-16 lh-24 mb-2" }
const _hoisted_2 = { class: "body-medium-text-14 lh-20 neutral--text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tm_icon = _resolveComponent("tm-icon")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["report-empty", {
      'report-empty--row': _ctx.row,
      [`report-empty--${_ctx.kebabSize}`]: _ctx.size,
    }])
  }, [
    _createVNode(_component_tm_icon, {
      name: _ctx.icon,
      "custom-size": "72px"
    }, null, 8, ["name"]),
    _createElementVNode("div", null, [
      _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.title), 1),
      _createElementVNode("div", _hoisted_2, [
        _renderSlot(_ctx.$slots, "default", {}, () => [
          _createTextVNode(_toDisplayString(_ctx.text), 1)
        ], true)
      ])
    ])
  ], 2))
}