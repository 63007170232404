
import { defineComponent, ref } from 'vue'
import TmToggleButtons from '@/components/shared/TmToggleButtons.vue'
import TmButton from '@/components/shared/TmButton.vue'
import LineChart from '@/components/shared/charts/LineChart.vue'
import ReportGraph from '@/components/shared/templates/reports/ReportGraph.vue'
import type { PropType } from 'vue'

export default defineComponent({
  components: {
    TmButton,
    TmToggleButtons,
    LineChart,
    ReportGraph,
  },
  props: {
    getTimelineData: {
      type: Function as PropType<(week: boolean) => Record<string, any>>,
    },
  },
  setup() {
    const currentGraph = ref('day')

    return {
      currentGraph,
    }
  },
})
