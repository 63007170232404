
import { defineComponent } from 'vue'
import TmTooltip from '@/components/shared/TmTooltip.vue'
import WhiteBlock from '@/components/shared/templates/WhiteBlock.vue'

export default defineComponent({
  components: {
    TmTooltip,
    WhiteBlock,
  },
  props: {
    title: {
      type: String,
    },
    tooltip: {
      type: String,
    },
    noGap: {
      type: Boolean,
    },
    hideTitle: {
      type: Boolean,
    },
  },
})
