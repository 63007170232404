import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, renderSlot as _renderSlot, resolveComponent as _resolveComponent, mergeProps as _mergeProps, withCtx as _withCtx, createBlock as _createBlock, normalizeStyle as _normalizeStyle } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_report_card = _resolveComponent("report-card")!

  return (_openBlock(), _createElementBlock("div", {
    class: "report-cards",
    style: _normalizeStyle({
      'grid-template-columns': _ctx.computedColumnsStyle,
    })
  }, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.cards, (item, i) => {
      return (_openBlock(), _createBlock(_component_report_card, _mergeProps({
        key: i,
        "show-actions": _ctx.showActions
      }, item, { "no-hover": _ctx.noHover }), {
        default: _withCtx(() => [
          _renderSlot(_ctx.$slots, "default", {}, undefined, true)
        ]),
        _: 2
      }, 1040, ["show-actions", "no-hover"]))
    }), 128))
  ], 4))
}