import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderSlot as _renderSlot, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-05f42522"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "report-card__title" }
const _hoisted_2 = {
  key: 0,
  class: "tooltip-wrap"
}
const _hoisted_3 = { class: "text-center lh-20" }
const _hoisted_4 = {
  key: 1,
  class: "light--text"
}
const _hoisted_5 = { class: "report-card__value" }
const _hoisted_6 = { class: "report-card__counter" }
const _hoisted_7 = {
  key: 0,
  class: "report-card__counter-after"
}
const _hoisted_8 = { class: "text-center lh-20" }
const _hoisted_9 = {
  key: 1,
  class: "report-card__previously"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tm_button_more = _resolveComponent("tm-button-more")!
  const _component_tm_dropdown = _resolveComponent("tm-dropdown")!
  const _component_tm_icon = _resolveComponent("tm-icon")!
  const _component_tm_tooltip = _resolveComponent("tm-tooltip")!
  const _component_tm_chip = _resolveComponent("tm-chip")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["report-card", {
      'report-card--active': _ctx.dropdownActive,
      'report-card--no-hover': _ctx.noHover,
    }])
  }, [
    (_ctx.showActions)
      ? (_openBlock(), _createBlock(_component_tm_dropdown, {
          key: 0,
          modelValue: _ctx.dropdownActive,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.dropdownActive) = $event))
        }, {
          menu: _withCtx(() => [
            _renderSlot(_ctx.$slots, "default", {}, undefined, true)
          ]),
          default: _withCtx(() => [
            _createVNode(_component_tm_button_more, {
              "btn-size": "medium",
              color: "transparent",
              "icon-size": "xLarge"
            })
          ]),
          _: 3
        }, 8, ["modelValue"]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_1, [
      _createTextVNode(_toDisplayString(_ctx.title) + " ", 1),
      (_ctx.tooltip)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _createVNode(_component_tm_icon, {
              name: "tmi-help-outline",
              class: "light--text pointer"
            }),
            _createVNode(_component_tm_tooltip, null, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.tooltip), 1)
              ]),
              _: 1
            })
          ]))
        : _createCommentVNode("", true),
      (_ctx.caption)
        ? (_openBlock(), _createElementBlock("span", _hoisted_4, " (" + _toDisplayString(_ctx.caption) + ") ", 1))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.counter), 1),
      (_ctx.counterAfter)
        ? (_openBlock(), _createElementBlock("div", _hoisted_7, _toDisplayString(_ctx.counterAfter), 1))
        : _createCommentVNode("", true),
      (_ctx.percent && !_ctx.isEmptyMode)
        ? (_openBlock(), _createBlock(_component_tm_chip, {
            key: 1,
            outline: "",
            color: _ctx.percent.change
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.percent.value) + " ", 1),
              (_ctx.percent.tooltip)
                ? (_openBlock(), _createBlock(_component_tm_tooltip, { key: 0 }, {
                    default: _withCtx(() => [
                      _createElementVNode("div", _hoisted_8, _toDisplayString(_ctx.percent.tooltip), 1)
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true)
            ]),
            _: 1
          }, 8, ["color"]))
        : _createCommentVNode("", true)
    ]),
    (_ctx.previously)
      ? (_openBlock(), _createElementBlock("div", _hoisted_9, " Previously: " + _toDisplayString(_ctx.isEmptyMode ? '-' : _ctx.previously), 1))
      : _createCommentVNode("", true)
  ], 2))
}