import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_report_cards = _resolveComponent("report-cards")!
  const _component_report_empty = _resolveComponent("report-empty")!
  const _component_campaigns_details_block = _resolveComponent("campaigns-details-block")!

  return (_openBlock(), _createBlock(_component_campaigns_details_block, { title: "Delivery" }, {
    text: _withCtx(() => [
      _renderSlot(_ctx.$slots, "default", {}, undefined, true)
    ]),
    default: _withCtx(() => [
      (!_ctx.isEmptyMode)
        ? (_openBlock(), _createBlock(_component_report_cards, {
            key: 0,
            class: "campaigns-details-delivery__stats",
            cards: _ctx.stats
          }, null, 8, ["cards"]))
        : (_openBlock(), _createBlock(_component_report_empty, {
            key: 1,
            icon: "tmi-chart-outline",
            title: "Delivery stats",
            text: "Delivery stats will show after the campaign is sent.",
            size: "small",
            row: ""
          }))
    ]),
    _: 3
  }))
}